<template>
    <div class="Minimaering">
        <div class="Minima_div clearfix">
            <div class="zui_left fl">
                <div class="zui_left_div">
                    <p class="le_p">草图</p>
                    <div class="le_img">
                        <span class="li_span_img">
                            <i class="el-icon-zoom-in" @click="dk_Dt()"></i>
                        </span>
                        <el-carousel>
                            <el-carousel-item v-for="(items,indexs6) in imageList" :key="indexs6">
                                <img :src="items.value" alt="">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
                <div class="zui_left_div">
                    <p class="le_p">推演测试</p>
                    <div class="le_img">
                        <video controls>
                            <source :src="ruleForm.p8.value" type="video/mp4">
                            您的浏览器不支持 video 标签。
                        </video>
                    </div>
                </div>
            </div>
            <div class="zui_right fr">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" label-width="120px" size="small" @submit.native.prevent>
                    <el-form-item label="现状：" prop="name">
                        <el-col :span="23">
                            <el-input type="textarea" v-model="ruleForm.p2.value" disabled></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="问题：" prop="name">
                        <el-col :span="23">
                            <el-input type="textarea" v-model="ruleForm.p3.value" disabled></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="创意（想法）：" prop="name">
                        <el-col :span="23">
                            <el-input type="textarea" v-model="ruleForm.p4.value" disabled></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="创新（想法）：" prop="name">
                        <el-col :span="23">
                            <div class="zui_three">
                                <ul class="zui_three_ul">
                                    <li class="zui_three_ul_li" v-for="(item,index) in ruleForm.p5.value" :key="index">
                                        <div class="zui_three_div clearfix">   
                                            <span class="fl">热度为{{item.level}}颗星的：</span>
                                            <span class="fl">
                                                <el-rate
                                                    v-model="item.level"
                                                    disabled
                                                    :colors="['#ff9900','#ff9900','#ff9900']">
                                                </el-rate>
                                            </span>
                                            <!-- <i class="el-icon-star-on" v-for="(citem,cindex) in item.level" :key="cindex"></i> -->
                                        </div>
                                        <ul class="icon_ul clearfix">
                                            <li class="zui_ul_li_item fl" v-for="(items3,indesx3) in item.values" :key="indesx3">
                                                {{items3.name}}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="方案（适应）：" prop="name">
                        <el-col :span="23">
                            <el-input type="textarea" v-model="ruleForm.p6.value" disabled></el-input>
                        </el-col>
                    </el-form-item>
                    <p style="font-size:14px;line-height:40px;padding-left:10px;font-weight:400;color:#333">行动（分工）：</p>
                    
                            <el-col :span="1">
                                <div style="width:2px;height:5px">

                                </div>
                            </el-col>
                        <el-col :span="11">
                            <el-form-item :label="ruleForm.p7.value.role1.title" label-width="80px" prop="name">
                                <el-input v-model="ruleForm.p7.value.role1.name" disabled></el-input>
                            </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item :label="ruleForm.p7.value.role2.title" label-width="80px" prop="name">
                                    <el-input v-model="ruleForm.p7.value.role2.name" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="1">
                                <div style="width:2px;height:30px">

                                </div>
                            </el-col>
                            <el-col :span="1">
                                <div style="width:2px;height:30px">

                                </div>
                            </el-col>
                            <el-col :span="1">
                                <div style="width:2px;height:30px">

                                </div>
                            </el-col>
                        <el-col :span="11">
                                <el-form-item :label="ruleForm.p7.value.role3.title" label-width="80px" prop="name">
                                    <el-input v-model="ruleForm.p7.value.role3.name" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item :label="ruleForm.p7.value.role4.title" label-width="80px" prop="name">
                                    <el-input v-model="ruleForm.p7.value.role4.name" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="1">
                                <div style="width:2px;height:30px">

                                </div>
                            </el-col>
                            <el-col :span="1">
                                <div style="width:2px;height:30px">

                                </div>
                            </el-col>
                            <el-col :span="1">
                                <div style="width:2px;height:30px">

                                </div>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item :label="ruleForm.p7.value.role5.title" label-width="80px" prop="name">
                                    <el-input v-model="ruleForm.p7.value.role5.name" disabled></el-input>
                                </el-form-item>
                            </el-col>
                </el-form>
            </div>
            <div class="Mini_popup">
                <el-dialog
                    title="查看大图"
                    :visible.sync="innerVisible"
                    width="800px"
                    :before-close="handleClose"
                    :show-close="false"
                >
                    <i class="el-icon-close closei" @click="closeis()"></i>
                    <div class="caotu_img">
                        <el-button-group>
                            <el-button type="primary" icon="el-icon-arrow-left" size="mini" @click="prePage">上一张</el-button>
                            <el-button type="primary" size="mini" @click="nextPage">下一张<i class="el-icon-arrow-right el-icon--right"></i></el-button>
                        </el-button-group>
                        <div style="marginTop: 10px; color: #409EFF">{{ pageNum }} / {{ pageTotalNum }}</div>
                        <img :src="item1.value" v-for="(item1,index1) in imgList" :key="index1" v-show="pageNum == index1 + 1 " alt="">
                    </div>
                </el-dialog>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            innerVisible:false,
            ruleForm:{
                p1:{},
                p2:{},
                p3:{},
                p4:{},
                p5:{
                    value:{
                        values:[]
                    }
                },
                p6:{},
                p7:{
                    value:{
                        role1:{},
                        role2:{},
                        role3:{},
                        role4:{},
                        role5:{}
                    }
                }
            },
            rules:{},
            imageList:[],
            imgList:[],
            pageNum: 1,
            pageTotalNum: 1
        }
    },
    methods: {
        getapp(){
            this.calId=this.$route.query.calId
            this.axios.projects9({
                token:this.$store.state.token,
                id:this.calId,
                tId:this.$store.state.tem_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data
                    this.ruleForm = res.data.data.data
                    this.imageList = this.ruleForm.p1.value
                    var str = this.form
                    this.$emit('info', str);
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        dk_Dt(){
            this.imgList = this.imageList
            this.pageTotalNum = this.imgList.length
            this.innerVisible = true
        },
        closeis(){
            this.innerVisible = false
        },
        handleClose(done){

        },
        // 上一页
        prePage() {
            let page = this.pageNum
            page = page > 1 ? page - 1 : this.pageTotalNum
            this.pageNum = page
        },
        // 下一页
        nextPage() {
            let page = this.pageNum
            page = page < this.pageTotalNum ? page + 1 : 1
            this.pageNum = page
        }
    },
    mounted() {
        this.getapp()
    }
}
</script>
<style scoped>
@import './../../../assets/css/teacher/Copypaper/CognitiveZJCX.css';
</style>
<style>
.le_img .el-carousel{
    width: 100%;
    height: 100%;
}
.le_img .el-carousel__container{
    height: 100%;
}
.le_img .el-carousel__arrow{
    display: none!important;
}
.le_img .el-carousel__arrow:hover{
    display: none!important;
}
.le_img .el-carousel__indicators{
    display: none;
}
.Mini_popup .el-dialog{
    margin-top: 8vh!important;
}
.Mini_popup .el-dialog__body{
    padding: 20px 0px 10px;
}
.Mini_popup .el-dialog__header{
    padding-top: 10px;
    text-align: center;
    border-bottom: 1px solid #DCDFE6;
    background: #4DA3D2;
}
.Mini_popup .el-dialog__title{
    font-size: 16px;
    font-weight: bold;
    color:#fff;
}
.zui_three_div .el-rate__item{
    vertical-align: top;
}
.zui_right .el-textarea.is-disabled .el-textarea__inner{
    color: #333;
    background: #fff;
    cursor: default;
}
.zui_right .el-input.is-disabled .el-input__inner{
    color: #333;
    background: #fff;
    cursor: default;
}
</style>